@font-face {
  font-family: "TheSans";
  font-weight: 100;
  src: url("./TheSans-Plain.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 200;
  src: url("./TheSans-Plain.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 300;
  src: url("./TheSans-Plain.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 400;
  src: url("./TheSans-Plain.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 500;
  src: url("./TheSans-Plain.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 600;
  src: url("./TheSans-Bold.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 700;
  src: url("./TheSans-Bold.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 800;
  src: url("./TheSans-Bold.otf");
}
@font-face {
  font-family: "TheSans";
  font-weight: 900;
  src: url("./TheSans-Bold.otf");
}
